.form-control {
    padding: 5px 10px;
    border: 1px solid #DCDCDC;
    border-radius: 8px;
}
.ant-picker.ant-picker-range.css-dev-only-do-not-override-14wwjjs.w-100 {
    padding: 8px 5px;
}
.pointer{
    cursor: pointer;
}