input.searchbar.mr-2 {
    padding: 8px;
}
.col-md-12.text-right.mt-3 {
    border-top: 1px solid #cdd4da;
    padding-top: 2rem;
    margin-top: 4rem;
}
button#dropdownMenuButton {
    padding: 10px 28px 10px 15px;
}