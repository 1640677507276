tr.deactive{
    background-color: #ffd9dd;
}

.pointer{
    cursor: pointer;
}

.activedeactiveicon {
    font-size: 18px;
    line-height: normal;
    vertical-align: middle;
}

.addDropdown {
    .dropdown-menu {
        left: initial !important;
        right: 0 !important;
        top: 110% !important;
        transform: none !important;
    }
}

.nowrap {
    white-space: nowrap;
}



.modal-dialog-md {
    max-width: 900px !important;
}