@import "../../scss/mixins";

.holidays{
    display: block;
    font-size: 14px;
    margin-bottom: 9px;
    position: relative;
    padding-left: 1px;
    cursor: pointer;
    &.active{ 
        color:$primaryColor;   
    }
    
    > .bold{
        font-weight: 500;
    }

    // &::before{
    //     content: "";
    //     height: 5px;
    //     width: 5px;
    //     position: absolute;
    //     background-color: #000;
    //     left: 0;
    //     top:8px;
    //     border-radius: 50%;
    // }
}

.form-check.ml-1.chekss {
    display: flex;
    align-items: center;
   width: 100%;
}
.inside_check {
    width: 15%;
    margin: 0px 10px;
}

.card .overLapList {
    max-height: 520px;
    overflow: auto;
}