span.table_dats {
    cursor: pointer;
}

.htmlCode{
    min-height: 300px !important;
}

.tox .tox-notification{
    display: none !important;
}