.react-datepicker-wrapper {
    width: 100%;
}

.removeIc {
    cursor: pointer;
    position: absolute;
    right: 18px;
    background: #f6f6f6;
    border-radius: 8px;
}
 .addIc {
    padding: 10px 12px;
    color: #fff;
    cursor: pointer;
    border-radius: 8px;
}
.pointer{
    cursor: pointer;
}